import React from "react";
import Card from "./Card";

export default function RichMediaContainer({ data }) {
  const { sections, name: tabname } = data || {};

  const renderSection = (section) => {
    const { name, items } = section;
    return (
      <div className="section_container">
        <div className="ml-24 py-3 px-6 rounded-3xl bg-orange-200 inline text-3xl sectionHeading">
          {name} ads
        </div>
        <div className="mt-8 grid lg:grid-cols-4 gap-4 mx-auto container">
          {items.map((item) => {
            return <Card section={name} cardDetail={item} tabName={tabname} />;
          })}
        </div>
      </div>
    );
  };

  return (
    <div className="">
      {sections.map((section, i) => {
        return <div key={i}>{renderSection(section)}</div>;
      })}
    </div>
  );
}
