import { useState } from "react";
import { Link } from "react-router";

const getRichMediaHref = (section, name, format) => {
  let anchorHref = "";
  anchorHref = `/rich_media_ads/${section}/${name}/${format}`;

  return anchorHref;
};

const getProgrammaticHref = (name, format) => {
  let anchorHref = "";
  anchorHref = `/programmatic_ads/${name}/${format}`;

  return anchorHref;
};

const Dropdown = (props) => {
  const { title, list, name, section } = props;
  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <div
      onClick={() => setShowDropdown(!showDropdown)}
      className="border shadow capitalize mt-2 text-center bg-orange-400 rounded  text-white w-1/2 relative"
    >
      {title}
      {showDropdown && (
        <ul className="absolute bg-white top-full rounded-lg w-full text-base shadow-xl text-slate-700">
          {list.map((format) => {
            return (
              <li>
                <Link
                  to={{
                    pathname: section
                      ? getRichMediaHref(section, name, format.name)
                      : getProgrammaticHref(name, format.name),
                  }}
                  className="p-2 text-sm"
                >
                  {format.display_name}
                </Link>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
