
import React from "react";
import Card from "./Card";

export default function ProgrammaticAdsContainer({data}) {
    const { name, list = [] } = data || {};

  return (
    <div className="grid lg:grid-cols-4 gap-4 mx-auto container">
      {list.map((item, i) => {
        return <Card key={i} cardDetail={item} tabName={name} />;
      })}
    </div>
  );
}
