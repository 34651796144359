/* eslint-disable jsx-a11y/iframe-has-title */
import { Link, useParams } from "react-router";

import "../App.css";
import "./style.css";
import Dropdown from "./components";
import { useContext } from "react";
import { GalleryContext } from "../context";

const RenderCurrentTabDemoList = ({ list }) => {
  return (
    <div className="right">
      {list.map((item) => {
        return (
          <div
            key={item.defaultImageURL}
            className="pb-3 flex flex-col items-center cursor-pointer bg-orange-200 box"
          >
            <h3 className="capitalize text-center">{`${item.display_name}`}</h3>
            <img src={item.defaultImageURL} alt={item.defaultImageURL} />
            <Dropdown
              title="Show Formats"
              list={item.formats}
              // section={section}
              name={item.name}
            />
          </div>
        );
      })}
    </div>
  );
};
const getTabData = (tabs) => {
  return tabs.find((tab) => {
    return tab.name === "programmatic";
  });
};

const ShowPage = () => {
  const { tabs } = useContext(GalleryContext);
  const { list } = getTabData(tabs);
  const { widgetName, format } = useParams();
  const currentWidget = list.find((item) => item.name === widgetName);

  const { formats } = currentWidget;
  const currentFormat = formats.find((item) => item.name === format);
  return (
    <div className="page-layout">
      <div className="relative left mt-16 flex h-screen justify-center mx-auto bg-no-repeat bg-center overflow-hidden">
        <span className="absolute left-10 p-3 cursor-pointer capitalize rounded bg-orange-200 inline">
          <Link to={{ pathname: "/" }}>back to home</Link>
        </span>
        <iframe src={currentFormat.iframeURL} className="iframe" />
      </div>
      <RenderCurrentTabDemoList list={list} />
    </div>
  );
};

export default ShowPage;
