import React from "react";

import Galllery from "./components/Galllery";
import "./App.css";

export default function Home() {
  return (
    <div className="bg-stone-50 container-fluid ">
      <div className="container-fluid text-orange-500 bg-orange-100 lg:text-8xl text-6xl text-center pb-10">
        <h1 className="lg:p-20 p-12">RediAds Gallery</h1>
      </div>
      <Galllery />
    </div>
  );
}
