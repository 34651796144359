import React, { useState } from "react";

import rediadsLogo from "../images/rediads-logo.svg";
import { navLinks } from "../constants/NavLinks";

export default function Header() {
  const [active, setActive] = useState(false);

  const getDropDown = (dropdownItems = []) => {
    if (dropdownItems.length) {
      return (
        <ul className="dropdown-menu">
          {dropdownItems.map((item) => {
            return (
              <li key={item.link}>
                <a className="dropdown-item" href={item.link}>
                  {item.title}
                </a>
              </li>
            );
          })}
        </ul>
      );
    }
  };

  const getNavLinks = () => {
    return (
      <nav className="flex items-center flex-1 mob-nav">
        <ul className="flex items-center flex-1 mob-nav-list">
          {navLinks.map((navItem) => {
            return (
              <li
                key={navItem.title}
                className={`${
                  navItem.dropdownItems ? "relative" : ""
                } nav-item p-5`}
              >
                <a className="nav-link" href={navItem.link}>
                  {navItem.title}
                </a>
                {getDropDown(navItem.dropdownItems)}
              </li>
            );
          })}
        </ul>
        <div className="login-btn">
          <a href="https://rediads.com/login">Login</a>
        </div>
      </nav>
    );
  };
  return (
    <div className="bg-orange-100 p-8 border-slate-300">
      <header className="grid mx-auto container fixed-nav">
        <div className="flex items-center">
          <button
            onClick={() => setActive(!active)}
            className={`show-mobile nav-bar-icon ${active ? "active" : ""}`}
            type="button"
          >
            <span></span>
          </button>
          <a href="https://rediads.com/">
            <span className="company-logo">
              <img alt="brand_logo" src={rediadsLogo} /> Rediads
            </span>
          </a>
          {getNavLinks(active)}
        </div>
      </header>
    </div>
  );
}
