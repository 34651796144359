import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router";
import "./index.css";
import App from "./App";
import ShowRichMediaAds from "./Showpages/showRichMediaAds";
import ShowProgrammaticAds from "./Showpages/showProgrammaticAds";
import Header from "./components/Header";
import Footer from "./components/Footer";
import axios from "axios";
import { GalleryContext } from "./context";

const getGAlleryConfigFromJeanie = async () => {
  const data = await axios.get(
    "https://static.symplor.com/gallery_assets/gallery_tabs_config.json"
  );
  // const data = await axios.get(
  //   "https://pub-21497f039dd94f2194cea51e2cc228b3.r2.dev/gallery_assets/gallery_tabs_config.json"
  // );
  if (data && data.data) {
    const { tabs = [] } = data.data || {};

    const root = ReactDOM.createRoot(document.getElementById("root"));
    root.render(
      <React.StrictMode>
        <Header />
        <GalleryContext.Provider value={{ tabs }}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<App />} />
              <Route
                path="rich_media_ads/:section/:client/:format"
                element={<ShowRichMediaAds />}
              />
              <Route
                path="programmatic_ads/:widgetName/:format"
                element={<ShowProgrammaticAds />}
              />
            </Routes>
          </BrowserRouter>
        </GalleryContext.Provider>

        <Footer />
      </React.StrictMode>
    );
  }
};
getGAlleryConfigFromJeanie();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
