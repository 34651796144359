import React from "react";

import ProgrammaticAdsContainer from "./Programmatic";
import RichMediaContainer from "./RichMedia";

export default function CardListContainer({ tabData }) {
  const { name } = tabData || {};
  const getCurrentTabContentCompoent = () => {
    switch (name) {
      case "rich_media":
        return <RichMediaContainer data={tabData} />;

      case "programmatic":
        return <ProgrammaticAdsContainer data={tabData} />;

      default:
        return <></>;
    }
  };
  return (
    <div className="cards_block w-screen pt-28">
      {getCurrentTabContentCompoent()}
    </div>
  );
}
