import React from "react";

const NativeAdIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      imageRendering="optimizeQuality"
      fillRule="evenodd"
      clipRule="evenodd"
      viewBox="0 0 497 511.757"
      fill="currentColor"
    >
      <path
        fillRule="nonzero"
        d="M317.072 67.341c3.962 4.119 3.834 10.671-.285 14.633-4.118 3.962-10.671 3.835-14.632-.284l-43.279-45.154v133.89c0 34.357 13.873 68.765 36.333 94.672 20.573 23.73 48.272 40.207 78.927 42.711 2.794-11.075 8.551-20.974 16.377-28.8 11.289-11.288 26.885-18.271 44.108-18.271 17.225 0 32.82 6.983 44.108 18.271C490.017 290.297 497 305.894 497 323.117c0 17.225-6.983 32.82-18.271 44.108-11.288 11.288-26.883 18.271-44.108 18.271-17.223 0-32.819-6.983-44.108-18.271-10.113-10.113-16.769-23.686-18.045-38.79-36.394-2.984-68.966-22.186-92.905-49.801-7.882-9.092-14.835-19.111-20.687-29.768v138.993c13.07 2.188 24.76 8.44 33.732 17.412 11.288 11.287 18.271 26.884 18.271 44.108 0 17.224-6.983 32.819-18.271 44.107-11.288 11.289-26.883 18.271-44.108 18.271-17.225 0-32.82-6.982-44.108-18.271-11.288-11.288-18.271-26.883-18.271-44.107s6.983-32.821 18.271-44.108c8.972-8.972 20.662-15.224 33.732-17.412V248.866c-5.878 10.706-12.868 20.764-20.793 29.885-23.936 27.554-56.47 46.705-92.799 49.682-1.276 15.104-7.93 28.677-18.045 38.792-11.289 11.288-26.885 18.271-44.108 18.271-17.225 0-32.82-6.983-44.108-18.271C6.983 355.937 0 340.342 0 323.117c0-17.223 6.983-32.82 18.271-44.108 11.288-11.288 26.883-18.271 44.108-18.271 17.223 0 32.819 6.983 44.108 18.271 7.826 7.826 13.58 17.725 16.375 28.799 30.61-2.512 58.272-18.938 78.823-42.593 22.522-25.921 36.439-60.382 36.439-94.789V35.783l-44 45.907c-3.962 4.119-10.514 4.246-14.633.284-4.119-3.962-4.246-10.514-.284-14.633l61.758-64.423a10.315 10.315 0 016.55-2.871 10.375 10.375 0 019.95 5.104l59.607 62.19z"
      />
    </svg>
  );
};

export default NativeAdIcon;
