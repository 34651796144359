import React from "react";

import { useState } from "react";
// import sympoloImage from "../images/sompolo.webp";
import "./cardStyle.css";

const Card = ({ section, cardDetail, tabName }) => {
  const {
    defaultImageURL,
    animatedImageURL,
    display_name,
    name,
    formats = [],
  } = cardDetail || {};
  const [showDropdown, setShowDropdown] = useState(false);
  const [currentImage, setCurrentImage] = useState(defaultImageURL);

  const getHref = (format) => {
    let anchorHref = "";
    if (tabName === "rich_media") {
      anchorHref = `/rich_media_ads/${section}/${name}/${format}`;
    } else if (tabName === "programmatic") {
      anchorHref = `/programmatic_ads/${name}/${format}`;
    }
    return anchorHref;
  };

  return (
    <div className="mb-10 lg:w-4/5 w-9/12 mx-auto flex flex-col items-center card_container">
      <div className="mb-4 card_title text-lg font-sans capitalize text-orange-400 font-semibold">
        {display_name}
      </div>
      <div
        onMouseEnter={() => setCurrentImage(animatedImageURL)}
        onMouseLeave={() => setCurrentImage(defaultImageURL)}
        className="w-64 aspect-[1/2] ad_image_container rounded-3xl border-2 border-slate-200 overflow-hidden"
      >
        <img className="h-full aspect-[1/2]" alt="test" src={currentImage} />
      </div>

      <div
        onClick={() => setShowDropdown(!showDropdown)}
        className="relative mt-4 flex justify-center cursor-pointer items-center relative text-white font-bold text-center h-12 demo_dropdown w-4/5 bg-orange-500 rounded-lg"
      >
        Demos
        {showDropdown && (
          <ul className="absolute bg-white top-full rounded-lg w-full text-base shadow-xl text-slate-700">
            {formats.map((item) => {
              return (
                <li className="p-2 hover:bg-slate-300">
                  <a target="_blank" href={getHref(item.name)} rel="noreferrer">
                    {item.display_name}
                  </a>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
};

export default Card;
