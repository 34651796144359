export const navLinks = [
  {
    title: "Solutions",
    link: "#",
    dropdownItems: [
      {
        link: "/solutions/for-adsense",
        title: "For AdSense Publishers",
      },
      {
        link: "/solutions/for-enterprise",
        title: "For Enterprise Publishers",
      },
      {
        link: "/solutions/for-video",
        title: "Video Ads Solutions",
      },
    ],
  },
  {
    link: "https://rediads.com/casestudies",
    title: "Case Studies",
  },
  {
    link: "https://rediads.com/docs-home/",
    title: "Resources",
  },
  {
    link: "https://rediads.com/about",
    title: "About",
  },
  {
    link: "https://blog.rediads.com",
    title: "Blog",
  },
];
